@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
* {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
}
body, html, #root {
  height: 100%;
  background-image: url(./img/radiance.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
p {
  letter-spacing: .5px;
}
span {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.btn {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
* {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
}
body, html, #root {
  height: 100%;
  background-image: url(/app/static/media/radiance.79ad656b.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
p {
  letter-spacing: .5px;
}
span {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.btn {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}
.App {
  height: 100%;
  background-image: url(/app/static/media/stars.836cf9dc.svg);
  background-size: 80%;
}
.Header {
  position: relative;
  width: auto;
  height: 150px;
  padding: 20px;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 11%;
  z-index: 2;
}
.Logo {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.logo-img {
  height: 50px;
}
.logo-text {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  color: #dee0ee;
  text-transform: uppercase;
}
.logo-text-container span {
  display: block;
  color: #7eadf0;
}
.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.profile-balance {
  margin-right: 18px;
}
.profile-balance-border {
  width: auto;
  height: 40px;
  white-space: nowrap;
  background-image: linear-gradient(#28cbd3, #7057fa);
  padding: 2px;
  border-radius: 10px;
}
.profile-balance-btn a {
  padding: 0 3em;
}
.profile-balance-btn {
  background: #242b51;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-account {
  height: 46px;
  padding: 0 8px;
  background-color: #1f2752;
  border-radius: 10px;
  box-shadow: 0 16px 32px rgb(24 32 58 / 12%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-account span {
  text-transform: none;
}
.profile-wallet {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
}
.profile-wallet:nth-child(2) {
  text-transform: none;
}
.profile-wallet-img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.popup-window {
  width: 380px;
  height: 325px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #1f2752;
  border-radius: 10px;
  box-shadow: 0 24px 80px 0 rgb(0 0 0 / 16%);
}
.popup-content {
  padding: 24px;
}
.popup-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}
.settings {
  height: 20px;
  opacity: 0.7;
}
form {
  font-weight: 700;
}
.form-input-container {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
form input {
  width: 100%;
  height: 66px;
  padding: 30px 12px 10px;
  padding-right: 95px;
  background-color: #1a2046;
  color: #dee2ee;
  font-size: 14px;
  border: 2px solid #1a2046;
  border-radius: 10px;
  box-sizing: border-box;
}
form input.balanceaddr {
  padding-right: 0px;
}
form input:focus {
  border-color: #359ef6;
}
form label {
  position: absolute;
  top: 10px;
  left: 12px;
  font-size: 10px;
  color: #9eaacc;
  text-transform: uppercase;
  z-index: 1;
}
form input#balance {
  font-size: 20px;
  font-weight: 700;
}
form input::-webkit-input-placeholder {
  color: #dee2ee;
}
form input:-ms-input-placeholder {
  color: #dee2ee;
}
form input::placeholder {
  color: #dee2ee;
}
.input-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-logo {
  width: 22px;
}
.input-logo-container {
  position: absolute;
  bottom: 12px;
  right: 12px;
}
.input-logo-text {
  padding-left: 6px;
  text-transform: uppercase;
  color: white;
  font-size: 14px;
}
.form-btn {
  width: 100%;
  height: 46px;
  background-image: linear-gradient(180deg, #359ef6, #0662df);
}
.popup-account {
  width: 100%;
  height: 325px;
  max-width: 400px;
  position: absolute;
  top: calc(100% + 18px);
  right: 0;
  background-color: #1f2752;
  border-radius: 10px;
  box-shadow: 0 24px 80px 0 rgb(0 0 0 / 16%);
  z-index: 1;
}
.account-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
}
.popup-account-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  padding-bottom: 0px;
}
.close-popup-btn {
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
}
.account {
  width: 85%;
  background-color: #1a2046;
  border-radius: 10px;
  height: 115px;
  padding: 10px;
  box-sizing: border-box;
}
.account-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  font-size: 10px;
  font-weight: 700;
}
.account-top span {
  font-size: 10px;
  color: #9eaacc;
}
.account-top .change {
  padding: 5px 10px;
  background-color: #323a6d;
  color: #dee0ee;
  border-radius: 6px;
  text-transform: uppercase;
}
.account-address {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 19px;
}
.account-address span {
  font-size: 20px;
  text-transform: none;
}
.account-address-logo {
  height: 20px;
  margin-right: 10px;
}
.account-actions span {
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  opacity: 0.7;
}
.copy {
  margin-right: 20px;
}
.account-actions img {
  height: 10px;
  margin-right: 6px;
}
.popup-account-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 110px;
  padding: 24px;
  box-sizing: border-box;
  background-color: #323a6d;
  text-align: left;
  border-radius: 0 0 10px 10px;
  padding: 24px;
  font-weight: 700;
}
.popup-account-bottom p {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 12px;
  color: #9eaacc;
}
.link {
  font-size: 14px;
  color: #5fb9ff;
  text-decoration: underline;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
li {
  list-style-type: none;
}
.menu {
  position: absolute;
  right: 2%;
  width: 30px;
  height: 30px;
  opacity: 0.6;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
}
.menu-line {
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background-color: #1796ff;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  position: absolute;
}
.mobile-menu {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 0;
  display: none;
  overflow: hidden;
  background-color: #141935;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease;
}
.isMobileShown {
  height: calc(100vh - 90px);
}
.Logo img {
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
}
.menu-line:first-child {
  -webkit-transform: translateY(-9px);
          transform: translateY(-9px);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.menu-line:nth-child(3) {
  -webkit-transform: translateY(9px);
          transform: translateY(9px);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.menu-line-down {
  -webkit-transform: translateY(0px) !important;
          transform: translateY(0px) !important;
}
.menu-line-up {
  -webkit-transform: translateY(0px) !important;
          transform: translateY(0px) !important;
}
.mobile-menu-display {
  display: none;
}
.profile-balance-btn:hover {
  background-image: linear-gradient(#28cbd3, #7057fa);
}
.profile-account a:hover {
  background-color: #323a6d;
}
.form-btn:hover {
  background-image: linear-gradient(180deg, #0662df, #359ef6);
}
.Logo img:hover {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.max {
  margin-right: 13px;
  padding: 4px 8px;
  background-color: #323a6d;
  font-size: 10px;
  color: #dee0ee;
  border-radius: 6px;
  cursor: pointer;
}
.balance {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 10px;
  color: #9eaacc;
  text-transform: uppercase;
}
.form-btns-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-btns-container button {
  width: 45%;
}
.form-btns-container button:nth-child(2) {
  background: #323a6d;
  color: #9eaacc;
}
.form-btns-container .secondStep {
  background: #1f2752;
  border: 3px solid #20938a;
  background-image: url(/app/static/media/ok.e1d7307f.svg);
  background-repeat: no-repeat;
  background-size: 1.6em;
  background-position: 18% 48%;
  padding-left: 20px;
}
.form-btns-container .secondStep:nth-child(2) {
  background-image: linear-gradient(180deg, #359ef6, #0662df);
  padding: 0;
  border: none;
  color: white;
}
.process-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 190px;
}
.process-logo {
  height: 100px;
}
.process-text {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: white;
  padding: 5px;
  line-height: 1.3em;
}
.result-info-container {
  margin-top: 10px;
  text-align: center;
}
.result-info-container p {
  font-size: 12px;
  color: #9eaacc;
  padding-bottom: 5px;
}
.amount-btn {
  background: #323a6d;
  color: #9eaacc;
}
@media screen and (max-width: 1400px) {
  .Header {
    margin: 0 4%;
  }
}
@media screen and (max-width: 1100px) {
  .Header {
    margin: 0 1%;
  }
}
@media screen and (max-width: 380px) {
  .popup-window {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .profile,
  .logo-text {
    display: none;
  }
  .Header {
    margin: 0;
    padding: 0;
    background-color: rgba(20, 25, 53, 0.9);
    height: 90px;
  }
  .Logo {
    position: absolute;
    right: 50%;
    -webkit-transform: translate(50%);
            transform: translate(50%);
  }
  .menu {
    display: flex;
  }
  .popup-account {
    position: relative;
    top: 0;
  }
  .profile-balance {
    margin: 0;
    margin-top: 20px;
  }
  .profile-balance-border,
  .profile-account,
  .popup-account {
    width: 300px;
  }
  .profile-account {
    margin: 20px 0;
    box-sizing: border-box;
    width: 300px;
    justify-content: space-between;
  }
  .mobile-menu {
    display: flex;
  }
}
.info-popup {
  z-index: 1;
  /* left: 0%;
  transform: translate(-100%, -50%); */
}

.popup-info-content {
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
}
.close-btn {
  height: 1.2em;
  position: absolute;
  top: 2%;
  right: 2%;
  opacity: 0.5;
  cursor: pointer;
}
.address-validator {
  color: #e62424;
}

